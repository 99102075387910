import { NUMBER_OF_EVENTS } from '~/composables/product/constants'
import type { KftDynamicContentMetrics } from '~/utils/kftApiUtils'

export const useStaticMetrics = (): KftDynamicContentMetrics => {
  const { locale } = useI18n()
  const eventCount = formattedNumberOfEvents(locale.value, NUMBER_OF_EVENTS)

  return {
    nrEvents: null,
    nrPartners: null,
    minPrice: null,
    maxPrice: null,
    minDuration: null,
    maxDuration: null,
    totalRatings: null,
    avgRating: null,
    allEvents: eventCount.toString(),
  }
}
